.container {
    .header-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        h1 {
            margin: 0;
        }
        .title {
            margin: 30px;
        }
    }
}
