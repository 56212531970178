@import 'rsuite/dist/rsuite.min.css';
@import './styles/screenSizes.scss';
@import '~bootstrap/scss/bootstrap';
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-x: auto;

    .navbar-section {
        width: 100%;
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 8px #0000001a;
        padding: 0.5rem 1rem;
        align-items: center;

        .hamburger {
            display: none;
        }
    }
    .main-page {
        display: flex;
        position: relative;
        flex: 1;
        width: 100%;
        height: calc(100vh - 56px);
        flex-direction: row;
        justify-content: flex-start;
        overflow: hidden;
        .main-page-content {
            flex: 1;
            padding: 1rem;
            overflow-y: scroll;
            /* this will hide the scrollbar in mozilla based browsers */
            overflow: -moz-scrollbars-none;
            /* this will hide the scrollbar in internet explorers */
            -ms-overflow-style: none;
            height: 100%;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: initial;
    font-size: initial;
}

@media screen and (max-width: $smallDesktop) {
    .App {
        .navbar-section {
            .hamburger {
                display: block;
                margin-right: 1rem;
                z-index: 51;
                cursor: pointer;
            }
        }
    }
}

* {
    font-family: 'Open Sans', sans-serif !important;
}

@media screen and (max-width: $tablet) {
    .App {
        .main-page {
            &.show {
                overflow-y: hidden;
            }

            .main-page-content {
                padding: 0.5rem;
            }
        }
    }
}
