.duration-tabs-container {
    .nav-tabs {
        border: 0px;

        .nav-link {
            color: #9f9f9f;
            font-size: 23px;
            font-weight: normal;
        }
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #0090b5;
        border: 0px;
        border-bottom: 2px solid #0090b5;
        border-top-left-radius: 0rem;
        border-top-right-radius: 0rem;
        font-size: 23px;
        font-weight: normal;
    }
    .nav-tabs .nav-link {
        margin-bottom: -1px;
        background: none;
        border: 1px solid transparent;
        color: #9f9f9f;
    }

    .nav-item {
        button {
            padding: 0;
            margin-right: 1rem;
        }
    }
}
