.container {
    display: flex;
    flex-direction: column;

    .description {
        text-align: center;
        margin-bottom: 2rem;
    }

    .button {
        margin: auto;
        padding: 0 4rem;
        width: fit-content !important;
    }
}
