@import '../../styles/colors.scss';
@import '../../styles/screenSizes.scss';
@import '../../styles/mixins.scss';

.gradient-btn-container {
    @include gradient;
    cursor: pointer;
    min-width: 146px !important;
    height: 46px !important;
    // margin: 20px 0px 0px;

    &.outLinedPrimary {
        @include outLinedPrimary;
    }

    &.outLinedWhite {
        @include outLinedWhite;
    }

    &.gradient {
        @include gradient;
    }

    &.solidPrimary {
        @include solidPrimary;
    }

    &.solidWhite {
        @include solidWhite;
    }

    &.light {
        @include light;
    }

    &.redGradient {
        @include redGradient;
    }

    &.lightRed {
        @include lightRed;
    }

    &.disabled {
        background: gray;
    }
}

.gradient-btn-container:hover {
    &.hover-outLinedPrimary {
        @include outLinedPrimary;
    }

    &.hover-outLinedWhite {
        @include outLinedWhite;
    }

    &.hover-gradient {
        @include gradient;
    }

    &.hover-solidPrimary {
        @include solidPrimary;
    }

    &.hover-solidWhite {
        @include solidWhite;
    }

    &.hover-light {
        @include light;
    }

    &.hover-redGradient {
        @include redGradient;
    }

    &.hover-lightRed {
        @include lightRed;
    }
}

@media screen and (max-width: $mobile) {
    .gradient-btn-container {
        min-width: 120px !important;
        height: 45px !important;
        button {
            width: 100%;

            &:hover {
                transform: scale(1.025);
            }
        }
    }
}
