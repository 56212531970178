@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.blog-des-container {
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 20px;
    font-family: 'Open Sans', sans-serif;
    max-width: $max-width;
    margin: auto;

    .card-des-img {
        max-width: 100%;
        height: auto;
        max-height: 850px;
    }
    .card-des-time {
        color: #807373;
        font-size: 15px;
        margin-top: 5px;
        overflow-wrap: break-word;
    }
    .card-des-title {
        color: #44a8de;
        font-size: 20px;
        margin-top: 5px;
        overflow-wrap: break-word;
        width: 50%;
    }
    .card-des-des {
        color: #343434;
        font-size: 20px;
        margin-top: 5px;
        overflow-wrap: break-word;
        text-decoration: ltr;
        width: 50%;
        a {
            color: $primary !important;
        }
    }
}
.btns-container {
    margin: 3rem 0px 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .delete-btn {
        margin-right: 1rem;
    }

    .delete-btn:hover {
        border: 1px solid #fc4854;
    }
}

@media screen and (max-width: $tablet) {
    .blog-des-container {
        .card-des-des,
        .card-des-img,
        .card-des-title,
        .card-des-des {
            width: 100%;
        }
    }
    .btns-container {
        width: 100%;
    }
}

@media screen and (max-width: $mobile) {
    .btns-container {
        flex-direction: column;
        .delete-btn {
            margin-bottom: 1rem;
        }
    }
}
