@import '../../../../styles/screenSizes.scss';
@import '../../../../styles/colors.scss';

.container {
    width: 100%;
    border: 1px $border solid;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1rem;

    .empty {
        text-align: center;
        color: $text-light;
    }

    .duration {
        padding: 1rem;
        margin: 0 1rem;
        display: flex;
        flex: 1;

        &.deactivated {
            background-color: #eee;
            border-radius: 5px;
        }

        &.line-break {
            border-bottom: 1px solid $border;
        }

        &:hover {
            background-color: var(--bs-light);
            border-radius: 5px;

            .duration-head {
                cursor: pointer;
            }
        }

        p {
            margin-bottom: 0;
        }

        .duration-head {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 25%;

            h6 {
                color: $text-dark;
            }
            p {
                color: $text-light;
            }
        }

        .weeks {
            display: flex;
            justify-content: space-evenly;
            height: 100%;
            flex: 1;

            .week {
                display: flex;
                flex-direction: column;

                .title {
                    color: $text-dark;
                }
                .check {
                    display: inline-block;
                    align-self: center;
                    margin: auto;
                    cursor: pointer;
                }
                .check:checked {
                    background-color: $primary;
                    border-color: $primary;
                }
            }
        }
    }
}

@media only screen and (max-width: $tablet) {
    .container {
        .duration {
            flex-direction: column;

            .duration-head {
                margin-bottom: 1rem;
            }

            .weeks {
                flex-direction: column;

                .week {
                    flex-direction: row;
                    justify-content: space-between;
                    width: 50%;
                    min-width: 10rem;
                    max-width: 100%;

                    .check {
                        margin: 0;
                    }
                }
            }
        }
    }
}
