@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD
@import '../../../styles/screenSizes.scss';

.calender-content {
    width: 100%;
    height: 100%;

    .calender {
        height: 69vh;

        .rbc-event {
            width: 120%;
            background-color: #e8f8fb;
            color: black;

            .rbc-event-content {
                .event-container {
                    width: 100%;
                    height: 100%;

                    .dropdown {
                        position: relative;
                        float: right;
                        text-align: end;

                        img {
                            height: 20px;
                        }
                    }
                }
            }
        }
        .rbc-event-active {
            background-color: #e8f8fb;
        }

        .rbc-event-inactive {
            background-color: #eeeeee;
        }

        .rbc-toolbar {
            display: none;
        }
        .rbc-time-view .rbc-allday-cell {
            display: none;
            border: solid;
        }

        .rbc-time-view .rbc-row {
            min-height: 50px;

            span {
                color: #343434;
                margin-top: 15px;
                font-size: 18px;
                font-weight: normal;
                display: flex;
                justify-content: center;
            }
        }
        .rbc-time-column .rbc-timeslot-group {
            min-height: 73px;
        }

        .rbc-header > a {
            cursor: default;
        }
    }
}
.duration-modal-view {
    display: flex;
    flex-direction: column;

    .row-buttons {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: $tablet) {
    .calender-content {
        overflow: scroll;
        /* this will hide the scrollbar in mozilla based browsers */
        overflow: -moz-scrollbars-none;
        scrollbar-width: none;
        /* this will hide the scrollbar in internet explorers */
        -ms-overflow-style: none;

        .calender {
            width: 1000px;
        }
    }
}
